<template>
  <el-form-item :label="showLabel ? $t('common.field.accNum') : ''" prop="accountNumber">
    <el-select
      v-model="accountNumber"
      id="accountNumber"
      :no-data-text="$t('common.formValidation.noEligibleAccounts')"
      :disabled="disabled"
      data-testid="accountNumber"
    >
      <el-option
        v-for="item in accountNumberOptions"
        :key="item.mt4_account"
        :value="item.mt4_account"
        :label="item.mt4_account + ' - ' + item.currency"
        :data-testid="item.mt4_account"
      ></el-option>
    </el-select>
  </el-form-item>
</template>
<script>
import { apiQuery_mt_accounts } from '@/resource';
import { template } from '@babel/core';

export default {
  name: 'AccountNumber',
  props: {
    supportedCurrencies: String,
    supportedCurrenciesList: Array,
    showLabel: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    accountNumber(number) {
      const foundAccount = this.accountNumberOptions.find(f => f.mt4_account === number) || null;
      if (foundAccount != null) {
        this.$emit('setAccountNumber', number);
        this.$emit('setCurrency', foundAccount.currency);
      }
    },
    supportedCurrenciesList() {
      this.mapAccountOptions();
    }
  },
  mounted() {
    this.fetchTradingAccount();
  },
  data() {
    return {
      allAccountNumberOptions: [],
      accountNumberOptions: [],
      accountNumber: ''
    };
  },
  methods: {
    fetchTradingAccount() {
      apiQuery_mt_accounts({
        supportedCurrencies: 'all'
      }).then(resp => {
        if (resp.data.code == 0) {
          this.allAccountNumberOptions = resp.data.data;
          this.mapAccountOptions();
        }
      });
    },
    mapAccountOptions() {
      if (this.supportedCurrencies != 'all' && this.supportedCurrencies) {
        this.accountNumberOptions = this.allAccountNumberOptions.filter(f => f.currency == this.supportedCurrencies);
      } else if (this.supportedCurrenciesList) {
        this.accountNumberOptions = this.allAccountNumberOptions.filter(f =>
          this.supportedCurrenciesList.includes(f.currency)
        );
      } else this.accountNumberOptions = this.allAccountNumberOptions;
      if (this.accountNumberOptions.length > 0) {
        this.accountNumber = this.accountNumberOptions[0].mt4_account;
      } else {
        this.accountNumber = '';
      }
    }
  }
};
</script>
